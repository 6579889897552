<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="">
      <!--TODO add dialog to select if commission or final price -->
      <v-dialog width="500" v-model="dialogChoose">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon size="36">mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>{{$t("create_invoice")}}</v-card-title>
          <v-card-subtitle class="text-center mt-2 mt-0 primary--text">{{
            $t("who_invoice")
          }}</v-card-subtitle>
          <div class="close">
            <v-btn icon @click="dialogChoose = false" small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <base-border-card
                  :title="$tc('customer', 1)"
                  @card_clicked="generate('final')"
                ></base-border-card>
              </v-col>

              <v-col cols="6">
                <base-border-card
                  :title="$tc('artist', 1)"
                  @card_clicked="generate('commission')"
                ></base-border-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <v-card class="no_style">
      <v-card-text class="py-1">
        <InvoiceForm
          :selectable="true"
          :invoice="invoice"
          @validated="save()"
          @cancel="dialog = false"
          @close="dialog = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NewInvoiceForm",
  components: {
    InvoiceForm: () => import("@/components/invoices/Form"),
  },
  props: ["appointment"],
  data() {
    return {
      invoice: {
        type: "appointment",
        items: [],
        taxes: [],
        from_user_id: null,
        to_user_id: null,
      },
      dialog: false,
      dialogChoose: false,
    };
  },
  methods: {
    ...mapActions("", []),

    open() {
      console.log("OPEN FACTURA");
      this.dialogChoose = true;
      console.log("DIALOGCHOOSE", this.dialogChoose);
    },

    generate(type) {
      console.log("a");
      console.log(this.appointment);
      this.invoice.items = [];
      this.invoice.appointment_id = this.appointment.id;
      if (type == "final") {
        //SI NO TINC CUSTOMER
        if (this.appointment.customer.user.first_name == null) {
          //CREATE ALERT
          console.log("NO CLIENT");
          this.$alert(this.$t("no_client"));
          //RETURN
          return null;
        }

        this.invoice.from_user_id = this.appointment.studio_id;
        this.invoice.to_user_id = this.appointment.customer_id;
        this.invoice.to = this.appointment.customer.user;

        if (this.appointment.token_payment)
          this.invoice.items.push({
            description: "Paga y señal",
            price: this.appointment.token_payment,
            quantity: 1,
            reference_class: "appointment",
            reference_id: this.appointment.id,
            selected: true,
          });
        if (this.appointment.deposit)
          this.invoice.items.push({
            description: "Depsito",
            price: this.appointment.deposit,
            quantity: 1,
            reference_class: "appointment",
            reference_id: this.appointment.id,
            selected: true,
          });
      } else {
        //SI NO TINC ARTISTA
        if (this.appointment.tattooer_id == null) {
          console.log("NO ARTIST");
          //CREATE ALERT
          this.$alert(this.$t("no_artist"));
          //RETURN
          return null;
        }

        this.invoice.from_user_id = this.appointment.tattooer_id;
        this.invoice.to_user_id = this.appointment.studio_id;
        this.invoice.from = this.appointment.tattooer.user;
      }
      console.log(this.appointment.available_dates);
      this.appointment.available_dates.forEach((d, i) => {
        if (
          (d.sesion_price && type == "final") ||
          (d.sesion_commision && type != "final")
        ) {
          let price = type == "final" ? d.sesion_price : d.sesion_commision;
          if (type == "final" && i == 0) {
            price -= this.appointment.token_payment;
            price -= this.appointment.deposit;
          }

          this.invoice.items.push({
            description:
              "Sesión " +
              this.$d(new Date(d.date.substring(0, 10)), "date2digits"),
            price,
            quantity: 1,
            reference_class: "appointment_date",
            reference_id: d.id,
            selected: true,
          });
        }
      });

      this.dialog = true;
    },
    ...mapActions("invoices", ["addInvoice"]),
    save() {
      let invoice = this.invoice;
      invoice.items = invoice.items.filter((i) => i.selected);
      this.addInvoice(invoice).then(() => {
        this.$alert(this.$t("save_ok"));
        this.$emit("update");
        this.dialog = false;
      });
    },
  },
};
</script>
